<template>
	<div class="business">
		<w-navTab titleText="义警商圈"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<vant-list class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
			<div class="nav-top"><img src="@/assets/img/test250.png" alt="" /></div>
			<div class="middle">
				<div class="head-title">
					<w-tab :active="headerNavActive" :data="headerNav" @click="onHeaderTabChange"></w-tab>
				</div>

				<!-- 最新动态头部 -->
				<div class="business-news-bg" v-show="headerNavActive == 0">
					<scroll-tab :menu="businessNav" :active="businessNavActive" @click="onBusinessTabChange">
					</scroll-tab>
				</div>

				<!-- 公共列表 -->
				<div class="middle-item" v-for="(item, index) in businessList"
					@click="$router.push({ name: 'BusinessDetails', params: { id: item.id } })">
					<div class="middle-item-top">
						<div class="item-top-left">
							<div class="item-top-picture"><img :src="item.avatar" alt="" /></div>
							<div class="item-top-right">
								<div class="item-top-right-title">
									{{ item.user_name }} ({{ item.user_level }})
								</div>
								<div class="item-top-right-text">{{ item.district_name }}</div>
							</div>
						</div>
						<div class="item-top-right-box" @click.stop="onDelete(item.id, index)"
							v-if="headerNavActive == 1">
							删除</div>
					</div>
					<div class="middle-item-conner">
						<div class="conner-title">{{ item.title }}</div>
						<div class="conner-text">{{ item.content }}</div>
					</div>
					<div class="middle-item-picture">
						<div class="item-picture" v-for="(vt, index) in item.picture" v-if="index < 2">
							<img :src="vt" />
						</div>
					</div>
					<div class="middle-item-bottom">
						<div class="bottom-box">
							<div class="box-picture">
								<img src="@/assets/img/icon6.png" alt="" />
							</div>
							<div class="box-text">{{ item.create_time }}</div>
						</div>
						<div class="boottom-right-box">
							<div class="bottom-box">
								<div class="box-picture">
									<img src="@/assets/img/icon200.png" alt="" />
								</div>
								<div class="box-text">点赞 {{ item.approval }}</div>
							</div>
							<div class="bottom-box">
								<div class="box-picture">
									<img src="@/assets/img/icon201.png" alt="" />
								</div>
								<div class="box-text">浏览 {{ item.views }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</vant-list>
		<!-- 我的发布底部 -->
		<div class="footer" v-if="headerNavActive == 1" @click="$router.push({ name: 'BusinessCreate' })">
			<van-icon name="add" color="#3377FF" size="61px" />
		</div>
	</div>
</template>

<script>
import Business from '@/api/business';
import WTab from '@/components/Tab';
import ScrollTab from '@/components/ScrollTab';
import VantList from '@/components/VantList';
import VantVendor from '@/vendor/vant';
import LoadingPage from '@/components/LoadingPage';

export default {
	name: 'Business',
	data() {
		let headerNav = [
			{ tag: 1, title: '最新动态' },
			{ tag: 2, title: '我的发布' }
		];

		return {
			loading: true,
			finished: false,
			isEmpty: false,
			loadingPage: true,
			headerNavActive: 0,
			headerNav: headerNav,
			businessNavActive: 0,
			businessNav: [{ id: 0, title: '全部' }],
			businessList: [],
			paramsTag: 1,
			paramsPage: 1,
			paramsLimit: 10,
			paramsGroupId: 0
		};
	},
	created() {
		Business.group()
			.then(result => {
				this.businessNav = this.businessNav.concat(result.data);
			})
			.catch(error => {
				console.log(error);
			});

		this.onLoad();
	},
	methods: {
		onDelete(id, index) {
			VantVendor.Dialog.confirm({
				title: '您确认删除吗？',
			}).then(() => {
				Business.Delete({
					post_id: id
				}).then(result => {
					this.businessList.splice(index, 1)
					VantVendor.Toast.success('删除成功');
				}).catch(error => {
					console.log(error);
				})
			}).catch(() => {
				console.log('用户选择取消');
			});
		},
		onBusinessTabChange(index) {
			this.businessNavActive = index;
			this.paramsGroupId = this.businessNav[index].id;
			this.onReload(index);
			this.onLoad();
		},
		onHeaderTabChange(index) {
			this.headerNavActive = index;
			this.paramsTag = this.headerNav[index].tag;
			this.onReload(index);
			this.onLoad();
		},
		onReload(index) {
			this.isEmpty = false;
			this.finished = false;
			this.paramsPage = 1;
			this.businessList = [];
		},
		onLoad() {
			this.loading = true;

			Business.posts({
				tag: this.paramsTag,
				page: this.paramsPage,
				limit: this.paramsLimit,
				group_id: this.paramsGroupId
			}).then(result => {
				this.paramsPage++;
				this.businessList = this.businessList.concat(result.data.list);

				this.loading = false;
				this.loadingPage = false;

				if (this.businessList.length <= 0) {
					this.isEmpty = true;
				}

				if (this.businessList.length >= result.data.total) {
					this.finished = true;
				}
			}).catch(error => {
				console.log(error);
			});
		}
	},
	components: {
		WTab,
		VantList,
		ScrollTab,
		LoadingPage
	}
};
</script>

<style lang="less" scoped>
.business {
	position: relative;

	.business-news-bg {
		width: 100%;
		padding: 0 14px;
		margin: 12px 0;
		border-radius: 6px;
		box-sizing: border-box;
		background-color: #fff;
	}

	.nav-top {
		width: 100%;
		height: 200px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.middle {
		background: #f2f2f2;
		padding: 0 10px 0 10px;
		border-radius: 15px 15px 0 0;
		position: relative;
		z-index: 2;

		.head-title {
			padding: 15px 10px 0 10px;
			background: #f2f2f2;
			border-radius: 15px 15px 0px 0px;
			margin-top: -15px;
		}

		.head-nav {
			padding: 8px 14px;
			background-color: #fff;
			border-radius: 6px;
			margin-top: 12px;

			.head-nav-item {
				font-size: 16px;
				line-height: 24px;
				color: #666;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				div {
					margin-right: 20px;
				}
			}

			.color {
				font-weight: bold;
				color: #3377ff;
				border-bottom: 2px solid #3377ff;
			}
		}

		.middle-item {
			padding: 15px 10px;
			background: #ffffff;
			border-radius: 8px;
			margin-top: 10px;

			&:first-child {
				margin-top: 0;
			}

			.middle-item-top {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding-bottom: 10px;
				border-bottom: 1px dashed #ddd;
				margin-bottom: 10px;

				.item-top-left {
					display: flex;
					align-items: center;

					.item-top-picture {
						width: 50px;
						min-width: 50px;
						height: 50px;

						img {
							width: 100%;
							height: 100%;
							border-radius: 50%;
							object-fit: cover;
						}
					}

					.item-top-right {
						padding-left: 10px;

						.item-top-right-title {
							font-size: 18px;
							line-height: 24px;
							color: #333;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}

						.item-top-right-text {
							font-size: 14px;
							line-height: 14px;
							color: #666;
							margin-top: 7px;
						}
					}
				}

				.item-top-right-box {
					min-width: 40px;
					width: 40px;
					color: #D62111;
					font-size: 18px;
					line-height: 24px;
				}
			}

			.middle-item-conner {
				.conner-title {
					font-size: 18px;
					line-height: 24px;
					color: #333;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.conner-text {
					font-size: 15px;
					line-height: 20px;
					color: #666;
					margin-top: 10px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					word-break: break-all;
				}
			}

			.middle-item-picture {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 10px 0;

				.item-picture {
					width: 163px;
					min-width: 163px;
					height: 100px;

					img {
						width: 100%;
						height: 100%;
						border-radius: 6px;
						object-fit: cover;
					}
				}
			}

			.middle-item-bottom {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.bottom-box {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					margin-right: 8px;

					.box-picture {
						width: 16px;
						min-width: 16px;
						height: 16px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.box-text {
						font-size: 13px;
						line-height: 16px;
						color: #666;
						margin-left: 3px;
					}
				}

				.bottom-box:last-child {
					margin-right: 0;
				}

				.boottom-right-box {
					display: flex;
					justify-content: flex-start;
					align-items: center;
				}
			}
		}
	}

	.footer {
		position: fixed;
		bottom: 20px;
		z-index: 99;
		left: 50%;
		transform: translateX(-50%);
	}
}
</style>
